.form_group {
  padding-bottom: 5px;
}

.form_group_label {
  padding-right: 20px;
}

.form_control {
  width: 50%;
}

.map-container {
  position: relative;
}

.marker-description-container,
.marker-description-container-minimize {
  position: absolute;
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  max-width: 30%;
  max-width: 30%;
  background-color: #fff;
  z-index: 10;
  left: 5px;
  bottom: 5px;
  border-radius: 2px;
  box-shadow: 0px 0.5px 2px 0.5px rgba(0, 0, 0, 0.2);
  transition: all 300ms ease-out;
}

.marker-description-container-minimize {
  padding: 5px;
  width: 2rem !important;
  height: 2rem !important;
  cursor: pointer;
}

.marker-item {
  display: flex;
  gap: 10px;
  margin: 5px 0;
  align-items: center;
}

.marker-icon {
  width: 20px;
  height: 30px;
}

.marker-info {
  font-size: 0.8rem;
  font-weight: 500;
}

.minimize-btn {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #e4e4e4;
  border-radius: 3px;
  right: 5px;
  top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker-icon-btn-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

.marker-icon-btn {
  color: #606060;
}

.marker-icon-btn:hover {
  color: #000;
}

.minimize-btn:hover {
  background-color: #b5b5b5;
}
