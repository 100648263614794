.extraInfo {
  margin: 0 -5px;
}
.srTopInfoTitle {
  font-weight: bold;
}
.srAssignContent {
  font-weight: bold;
  margin-left: 10px;
}
.srAssignValues {
  margin-left: 5px;
}
.srAssignLeaveInfo {
  margin-left: -30px;
  display: flex;
}
.srAssignLeaveMessage {
  font-weight: bold;
  margin-left: 10px;
}

.loadingOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.593);
  display: flex;
  justify-content: center;
  align-items: center;
}
