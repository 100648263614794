.AddProductDiv{
    margin: 0px 20px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.AddProductButton{
    float:right;
}

.buttonLeftAlign{
    justify-content: flex-start !important
}

.buttonMargin{
    margin-right: 10px !important;
}

.formError {
    color: red;
    font-weight: 400;
  }