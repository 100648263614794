.search-results-container {
  margin-top: 7px;
  margin-bottom: 15px;
}

.search-item-container {
  background-color: #eee;
  color: #000;
  min-height: 70px;
  border-radius: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.search-item-container:hover {
  background-color: #fcd8d7;
  color: #000;
}

.search-item-title {
  font-weight: 500;
  font-size: 15px;
}

.search-paginate-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #e53935;
  border-color: #e53935;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #555;
  padding: 5px 10px;
  margin: 0px 5px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #e53935;
  border-color: #e53935;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #555;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.search-content{
  min-height: 120px;
  padding-bottom: 15px;
}