.cardCategoryWhite {
    color: rgba(255,255,255,.62);
    margin: 0;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}

.cardTitleWhite {
    color: #FFFFFF;
    margin-top: 0px;
    min-height: auto;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin-bottom: 3px;
    text-decoration: none;
}


.customerSDiv{
    margin: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.customersButton{
    float:right;
}

.appBarStyle{
    background-color: #eeeeee !important;
}
.contentHeader{
    padding: 10px 25px 0px 25px;
}
.contentBody{
    padding: 10px 25px 0px 25px;
}