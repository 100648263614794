.Component-horizontalScrollContainer-106 ::-webkit-scrollbar-thumb {
  border: 0px solid white !important;
  border-radius: 5px !important;
  background-color: rgba(0, 0, 0, 0.3);
}

.medium-button {
  padding: 9px 30px !important;
}

.rc-tree .rc-tree-treenode {
  font-size: 1rem;
  padding-bottom: 3px !important;
}

.add-new-data-div {
  margin: 20px;
  padding-top: 20px;
  padding-bottom: 5px;
}

.add-data-button {
  float: right;
}

/* MatrialTable Override Styles */

.grid-title-icon {
  display: inline-block;
  color: #f15642;
  vertical-align: bottom;
  padding-right: 10px;
}

.material-grid-wrapper > div {
  box-shadow: none;
}

.material-grid-wrapper table tbody tr:nth-child(1) td,
.material-grid-wrapper table tfoot tr td {
  border-bottom: none;
}

.material-grid-wrapper .MuiToolbar-regular {
  padding: 0px 10px 0px 10px;
}

.material-grid-wrapper .MuiInput-underline:after,
.material-grid-wrapper .MuiInput-underline:before {
  border-color: #d2d2d2;
}

.material-grid-wrapper .MuiInputAdornment-positionStart .material-icons {
  color: #f15642;
}

.material-grid-wrapper h6 {
  color: #f15642;
  text-transform: none;
  font-weight: 400;
  font-size: 21px;
  display: inline-block;
  vertical-align: middle;
}

.material-grid-wrapper .MuiTypography-body1 {
  font-weight: 300;
  color: #555555;
  font-size: 14px;
  text-transform: none;
}

.material-grid-wrapper .MuiTableCell-root {
  font-size: 14px;
  color: #3c4858;
}

.material-grid-wrapper .MuiTablePagination-toolbar {
  padding: 20px 0px;
}

.material-grid-wrapper .MuiButton-contained {
  background: linear-gradient(to right, #f15642, #f55856, #ed3b47);
  border-radius: 30px;
  font-size: 14px;
}

.MuiIconButton-root {
  padding: 0px 10px 0px 10px !important;
}

.material-grid-wrapper .MuiIconButton-root:hover {
  background: none;
}

.material-grid-wrapper .action-buttons {
  padding: 6px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
}

.material-grid-wrapper .view-button {
  background: #ff9200;
}

.material-grid-wrapper .edit-button {
  background: #4caf50;
}

.material-grid-wrapper .delete-button {
  background: #f44336;
}

/* MatrialTable Override Styles */

/*Autocomplete highlights*/
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.generic-button-class {
  background: linear-gradient(to right, #f15642, #f55856, #ed3b47);
  font-size: 12px;
  border-radius: 10px !important;
}

.genric-body-text {
  color: #3c485b;
}

.generic-page-title {
  padding: 20px 0 0 20px;
  color: #f15642;
  float: left;
}

.generic-page-title h6 {
  color: #f15642;
  text-transform: none;
  font-weight: 400;
  font-size: 21px;
  display: inline-block;
  vertical-align: bottom;
}

.generic-page-title-icon {
  display: inline-block;
}

.generic-form-container {
  padding: 20px;
}

.generic-page-title p {
  font-weight: 300;
  color: #555555;
  font-size: 14px;
  text-transform: none;
}

.generic-action-panel {
  float: right;
}

.generic-form-header-wrapper {
  display: inline-block;
  width: 100%;
}

.generic-button-outlined {
  border: 1px solid #f15642 !important;
  color: #f15642 !important;
  background-color: white !important;
  border-radius: 30px !important;
  box-shadow: none !important;
}

.MuiTabs-root {
  display: flex;
  overflow: hidden;
  min-height: 48px;
  background-color: #ffd3cd;
}

.MuiTab-textColorPrimary {
  color: #7b7b7b !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #f15642 !important;
}

.MuiTabs-indicator {
  background-color: #f15642 !important;
}

/* .makeStyles-content-3 {
    margin-top: 25px !important;
} */

/* Commented due to mui element margin issue */
/* .jss3 {
  margin-top: 25px !important;
} */

h4 {
  font-weight: 500;
  font-size: 1.4em;
}

.generic-header-text {
  padding-left: 7px;
}

.MuiFormControl-root {
  margin: 0px !important;
  padding-bottom: 8px !important;
}

.pac-container {
  z-index: 100000 !important;
}

.div-button-wrapper {
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 0.3125rem 1px;
  padding: 12px 20px 10px 20px;
  position: relative;
  font-size: 10px;
  min-width: auto;
  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
    0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
  min-height: auto;
  text-align: center;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  will-change: box-shadow, transform;
  touch-action: manipulation;
  border-radius: 3px;
  letter-spacing: 0;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: #999;
}

.div-button-wrapper:hover {
  box-shadow: 0 14px 26px -12px rgb(241 86 66 / 42%),
    0 4px 23px 0px rgb(0 0 0/12%), 0 8px 10px -5px rgb(241 86 66 / 20%);
  background-color: #f15642;
}

.MuiRating-label {
  color: #ffb400 !important;
  font-size: 24px;
}

.feedbackTable .MuiGrid-item {
  border: 1px solid black;
  padding: 5px 10px !important;
}

.MuiSwitch-switchBase {
  margin-top: 8px !important;
}

.generic-button-rounded {
  border: 1px solid #f15642 !important;
  color: #f15642 !important;
  background-color: white !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  box-shadow: none !important;
  margin-left: 15px !important;
  padding: 0px 0px !important;
}

.Component-horizontalScrollContainer-113 ::-webkit-scrollbar-thumb {
  border: 0px solid white !important;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
}

.Component-horizontalScrollContainer-113 ::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 15px !important;
}

.material-grid-wrapper .MuiInputAdornment-positionStart .material-icons {
  color: #a3655e;
  display: none;
}

.MuiPickersToolbar-toolbar , .MuiPickersClock-pin , .MuiPickersClockPointer-pointer  {
  background-color: #de5442 !important;
}

.MuiPickersClockPointer-thumb{
  border: 14px solid #de5442 !important
}

.MuiButton-textPrimary{
  color:#de5442 !important;
}

.MuiPickersDay-daySelected{
  background-color:#de5442 !important;
}
