.settingIconWrapper{
    text-align: center;
    display: block;
    padding: 10px 20px;
    min-height: 170px;
}
.setting_block{
    text-align: center;
    font-size: 48px;
    color: #F15642;
    border: 1px solid #F15642;
    border-radius: 50%;
    padding: 20px;
    display: inline-block;
}

.setting_text{
    font-size: 1rem;
    font-weight: 400;
    color: #F15642;
    display: block;
    padding: 12px 0 2px;
}
.desc_text{
    color: #3C4858;
    font-size: 12px;
    line-height: 1px;
}
.settingRow{
    margin-top: 100px !important;
}