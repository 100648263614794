.claims-setup-container .material-grid-wrapper .MuiToolbar-regular {
  display: none;
}
/* .claim-report-comment-box.MuiFormControl-root {
  margin-top: 10px !important;
  margin-left: -15px !important;
} */

.claim-report-comment-box {
  margin-top: 10px !important;
  /* margin-left: -15px !important; */
  font-size: 20 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

#employee_id {
  color: #a7a7a7 !important;
}
.claim-detail-table th {
  text-align: center;
}
