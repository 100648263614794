@import 'node_modules/leaflet.markercluster/dist/MarkerCluster.Default';
@import 'node_modules/leaflet.markercluster/dist/MarkerCluster';
// NOTE: '~leaflet/dist/leaflet.css' should be imported here instead of {preview-head.html}

$puple: #b70009d7;
$gray: #fee5e5;

.markercluster-map {
  height: 100%;
  width: 100%;
}

/* Customize the Clustered Markers */
.marker-cluster-custom {
  background: $puple;
  font-weight: bold;
  outline: 5px solid #b700094f;
  border-radius: 50%;
  color: $gray;
  height: 30px !important;
  width: 30px !important;
  line-height: 30px;
  text-align: center;
}

.marker-tool-tip {
  font-weight: bold;
  color: #444;
  text-align: center;
}

.ball {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  from {
    margin-top: 0;
  }
  to {
    margin-top: -5px;
  }
}
