
  .customerSDiv{
    margin: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.customersButton{
    float:right;
}

.appBarStyle{
  background-color: #eeeeee !important;
}

.setting_block{
  text-align: center;
  font-size: 48px;
  color: #F15642;
  border: 1px solid #F15642;
  border-radius: 50%;
  padding: 5px;
  margin-top: 10px;
  display: inline-block;
  cursor: pointer;
}

.map_container_height{
  height: 78vh;
}

