.breadcrumb {
  width: '100%';
  overflow-x: auto;
  white-space: nowrap;
}
.loadingOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.593);
  display: flex;
  justify-content: center;
  align-items: center;
}
