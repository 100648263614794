.breadcrumb {
  width: '100%';
  overflow-x: auto;
  white-space: nowrap;
}

.datasource {
  font-size: 122px;
  color: red;
  margin-left: 10rem;
  margin-bottom: 10px;
}

.title {
  font-size: 20px;
  margin-top: 10;
  font-weight: 'bold';
}

.title2 {
  font-size: 17px;
  margin-top: 10;
  font-weight: 'bold';
}

.paper {
  width: '14.5rem';
  padding: '0.5rem';
  border-radius: '0.8rem';
  background: 'rgba(237, 241, 245, 255)';

  /* font-size: 20px;
    margin-top: 10;
    font-weight: 'bold'; */
}
