.title {
  font-size: '1rem';
  margin-top: '0.7rem';
  margin-left: '2rem';
  display: flex;
  align-items: center;
  justify-content: center;
}

.formError {
  color: red;
  font-weight: 400;
}
