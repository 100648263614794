.appBarStyle {
  background-color: #eeeeee !important;
}

.AddProductAttributeDiv {
  margin: 20px;
  padding-top: 20px;
  float: right;
}
.loadingOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.593);
  display: flex;
  justify-content: center;
  align-items: center;
}
