.cardStyles {
  margin-bottom: 0px !important;
}

.extraInfoGrid {
  padding: '0 10px !important';
}

.buttonLeftAlign {
  justify-content: flex-start !important;
}

.buttonMargin {
  margin-right: 10px !important;
}

.label {
  /* margin-right: 10px !important; */
  color: #0000008a;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}
