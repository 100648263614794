.export-button {
  display: flex;
  align-items: center;
  border: 2px solid #bbb;
  color: white;
  padding: 7px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}
.export-button:hover {
  background-color: rgb(251, 215, 189);
  border: 2px solid #444;
}

.align-title {
  display: flex;
  justify-content: space-between;
}

.export-icon {
  color: 'primary';
  width: '20px';
  height: '20px';
}
