.breadcrumb { 
    list-style: none; 
    overflow: hidden; 
    display: flex;
    overflow-x: auto;
    font: 18px Sans-Serif;
    white-space: nowrap;
    padding-inline-start: 0px 
  }
  .breadcrumb li { 
    float: left; 
  }
  .breadcrumb li a {
    color: white;
    text-decoration: none; 
    padding: 10px 0 10px 65px;
    background: brown; /* fallback color */
    background: hsla(34,85%,35%,1); 
    position: relative; 
    display: block;
    float: left;
  }

  .breadcrumb li a::after { 
    content: " "; 
    display: block; 
    width: 0; 
    height: 0;
    border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
    border-bottom: 50px solid transparent;
    border-left: 30px solid hsla(34,85%,35%,1);
    position: absolute;
    top: 50%;
    margin-top: -50px; 
    left: 100%;
    z-index: 2; 
  }

  .breadcrumb li a::before { 
    content: " "; 
    display: block; 
    width: 0; 
    height: 0;
    border-top: 50px solid transparent;       
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px; 
    margin-left: 1px;
    left: 100%;
    z-index: 1; 
  }

  .breadcrumb li:first-child a {
    padding-left: 10px;
  }
  .breadcrumb li:nth-child(2) a       { background:        hsla(34,85%,39%,1); }
  .breadcrumb li:nth-child(2) a:after { border-left-color: hsla(34,85%,39%,1); }
  .breadcrumb li:nth-child(3) a       { background:        hsla(34,85%,43%,1); }
  .breadcrumb li:nth-child(3) a:after { border-left-color: hsla(34,85%,43%,1); }
  .breadcrumb li:nth-child(4) a       { background:        hsla(34,85%,47%,1); }
  .breadcrumb li:nth-child(4) a:after { border-left-color: hsla(34,85%,47%,1); }
  .breadcrumb li:nth-child(5) a       { background:        hsla(34,85%,51%,1); }
  .breadcrumb li:nth-child(5) a:after { border-left-color: hsla(34,85%,51%,1); }
  .breadcrumb li:nth-child(6) a       { background:        hsla(34,85%,55%,1); }
  .breadcrumb li:nth-child(6) a:after { border-left-color: hsla(34,85%,55%,1); }
  .breadcrumb li:nth-child(7) a       { background:        hsla(34,85%,59%,1); }
  .breadcrumb li:nth-child(7) a:after { border-left-color: hsla(34,85%,59%,1); }
  .breadcrumb li:nth-child(8) a       { background:        hsla(34,85%,63%,1); }
  .breadcrumb li:nth-child(8) a:after { border-left-color: hsla(34,85%,63%,1); }
  .breadcrumb li:nth-child(9) a       { background:        hsla(34,85%,68%,1); }
  .breadcrumb li:nth-child(9) a:after { border-left-color: hsla(34,85%,68%,1); }
  .breadcrumb li:nth-child(10) a       { background:        hsla(34,85%,72%,1); }
  .breadcrumb li:nth-child(10) a:after { border-left-color: hsla(34,85%,72%,1); }
  .breadcrumb li:nth-child(11) a       { background:        hsla(34,85%,76%,1); }
  .breadcrumb li:nth-child(11) a:after { border-left-color: hsla(34,85%,76%,1); }
  .breadcrumb li:nth-child(12) a       { background:        hsla(34,85%,80%,1); }
  .breadcrumb li:nth-child(12) a:after { border-left-color: hsla(34,85%,80%,1); }
  .breadcrumb li:nth-child(13) a       { background:        hsla(34,85%,84%,1); }
  .breadcrumb li:nth-child(13) a:after { border-left-color: hsla(34,85%,84%,1); }
  
