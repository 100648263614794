.cardCategoryWhite {
  color: rgba(255, 255, 255, 0.62);
  margin: 0;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.cardTitleWhite {
  color: #ffffff;
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-bottom: 3px;
  text-decoration: none;
}

.customerSDiv {
  margin: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.customersButton {
  float: right;
}

.appBarStyle {
  background-color: #eeeeee !important;
}
.inputTableRow {
  display: flex;
  flex-direction: row;
}
.inputDiv {
  padding: 4px;
}
.ruleInput {
  padding: 0px 3px 0px;
}
.addBtn {
  float: right;
}
.addBtnDiv {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
}
.submitBtnDiv {
  width: 65%;
}
.middleHeader {
  width: 66%;
  text-align: center;
  margin: 4px;
  height: 32px;
}
.otherHeader {
  width: 33%;
  text-align: center;
  margin: 4px;
  height: 32px;
}
.widthFull {
  width: 100%;
}
.contentBody {
  padding: 10px 25px 0px 25px;
}
.loadingOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.593);
  display: flex;
  justify-content: center;
  align-items: center;
}
