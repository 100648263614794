.adjustmentInput {
  background-color: white;
  height: 30px;
  font-size: 12px !important;
  border-radius: 0 !important;
}
.loadingOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.593);
  display: flex;
  justify-content: center;
  align-items: center;
}
