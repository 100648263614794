.treeContent{
    height: 55vh;
    overflow-y: auto;
}

.actionButtons{
    margin-left: auto !important;
}

.headerDiv{
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.roleIcon{
    font-size: 1.1rem !important;
    color:#00acc1;
}
.roleIconDiv{
    bottom: 2px !important;
    position: relative !important;
}

.appBarStyle{
    background-color: #eeeeee !important;
}
.cardTitleWhite {
color: #FFFFFF;
margin-top: 0px;
min-height: auto;
font-weight: 300;
font-family: "'Roboto', 'Helvetica', 'Arial', sans-serif";
margin-bottom: 3px;
text-decoration: none;

}