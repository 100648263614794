.formError{
    color: red;
    font-weight: 400;
}
.buttonLeftAlign{
    justify-content: flex-start !important
}

.buttonMargin{
    margin-right: 10px !important;
}